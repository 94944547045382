import React, { useState, useEffect, useRef } from "react";
import { useHistory, Link } from "react-router-dom";
import Authservice from "../services/auth.service";
import { ToastContainer } from "react-toastify";
import jwt from "jwt-decode";
import { errorToast } from "../react-toastfiy/toast";

function Navbar() {
  const history = useHistory();

  const token = localStorage.getItem("refreshToken");
  const user = jwt(token);

  const navHamburgerBtn = useRef(null);

  const logout = async () => {
    try {
      localStorage.clear();
      window.location.replace("/sign-in");
      // let formData = new FormData();
      // formData.append("phone", user.phone);
      // Authservice.logout(formData).then((res) => {
      //   {
      //     res.status === 200
      //       ? window.location.replace("/sign-in")
      //       : errorToast(res.msg);
      //   }
      // });
    } catch (error) {
      console.error(error);
      errorToast(error?.message);
    }
  };

  return (
    <>
      <ToastContainer />

      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          {user.role === `Territory Manager ${user.state}` && (
            <Link
              className="navbar-brand"
              to={`/tmDashboard:` + user.phone + "&state=" + user.state}
            >
              Meratractor ERP
            </Link>
          )}
          {user.role == "Auction Representative" && (
            <Link
              className="navbar-brand"
              to={`/arDashboard:` + user.phone + "&state=" + user.state}
            >
              Meratractor ERP
            </Link>
          )}
          {user.role !== "Auction Representative" &&
            user.role !== `Territory Manager ${user.state}` &&
            user.role !== "RTO Personnel" &&
            user.role !== "Account Personnel" && (
              <Link className="navbar-brand" to="/">
                Meratractor ERP
              </Link>
            )}
          {user.role === "RTO Personnel" && (
            <Link
              className="navbar-brand"
              to={`/rtoDashboard:` + user.phone + "&state=" + user.state}
            >
              Meratractor ERP
            </Link>
          )}
          {user.role === "Account Personnel" && (
            <Link
              className="navbar-brand"
              to={`/apDashboard:` + user.phone + "&state=" + user.state}
            >
              Meratractor ERP
            </Link>
          )}
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            ref={navHamburgerBtn}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link
                  className="nav-link active"
                  aria-current="page"
                  to="/user/user-home"
                  onClick={() => {
                    window.innerWidth < 992 && navHamburgerBtn.current.click();
                  }}
                >
                  User
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/bharartLoans/bharatLoans-home"
                  onClick={() => {
                    window.innerWidth < 992 && navHamburgerBtn.current.click();
                  }}
                >
                  BharatLoan
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to={
                    `/superAdminDashboard:` +
                    user.phone +
                    "&state=" +
                    user.state
                  }
                  onClick={() => {
                    window.innerWidth < 992 && navHamburgerBtn.current.click();
                  }}
                >
                  Dashboard
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/operation/operation-home"
                  onClick={() => {
                    window.innerWidth < 992 && navHamburgerBtn.current.click();
                  }}
                >
                  Operation
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to={
                    `/reports:` +
                    user.phone +
                    "&state=" +
                    user.state +
                    "&desig=" +
                    user.role
                  }
                  onClick={() => {
                    window.innerWidth < 992 && navHamburgerBtn.current.click();
                  }}
                >
                  Reports
                </Link>
              </li>
            </ul>

            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to={`/select-designation`}
                  onClick={() => {
                    window.innerWidth < 992 && navHamburgerBtn.current.click();
                  }}
                >
                  Roles
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to={`/Profile/${user.phone}`}
                  onClick={() => {
                    window.innerWidth < 992 && navHamburgerBtn.current.click();
                  }}
                >
                  Profile
                </Link>
              </li>

              <li className="nav-item">
                <button className="nav-link border-0 bg-light" onClick={logout}>
                  Logout
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
