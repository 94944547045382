import { createContext, useContext, useState } from "react";
import { errorToast } from "../react-toastfiy/toast";
import authService from "../services/auth.service";
// import { useHistory } from "react-router-dom";

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  const [user, setUser] = useState("");
  // const history = useHistory();
  const [loading] = useState(true);

  async function logIn(formdata) {
    const response = await authService.login(formdata);
    response.status === 200 && setUser(response.accessToken);
    response.status !== 200 && errorToast(response.msg);
    return response;
  }

  async function logOut() {
    const response = await authService.logout();
    setUser("");
    return response;
  }

  // if (loading) return "loading...";

  return (
    <userAuthContext.Provider value={{ user, setUser, loading, logIn, logOut }}>
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}
