import axios from "axios";

class SignZService {
  RTOSignZVehicleInfoAPI = (formdata) => {
    return axios({
      method: "post",
      url: `/operations/rto-signz-vehicle-info-api`,
      data: formdata,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  BackOfficeSignZVehicleInfoAPI = (formdata) => {
    return axios({
      method: "post",
      url: `/operations/back-office-signz-vehicle-info-api`,
      data: formdata,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  GetBackOfficeVehicleInfoVerificationAPI = (formdata) => {
    return axios({
      method: "post",
      url: `/operations/get-back-office-vehicle-info-verification-api`,
      data: formdata,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  SignZFailureCallback = (formdata) => {
    return axios({
      method: "post",
      url: `/users/signzy-failure-callback`,
      data: formdata,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
}

export default new SignZService();
