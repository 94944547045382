import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useCallback, useEffect, useState } from "react";
import { pdfjs, Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { useLocation } from "react-router-dom";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

const options = {
  cMapUrl: "/cmaps/",
  standardFontDataUrl: "/standard_fonts/",
  fontExtraPropertiesUrl: "/font_extra_properties/",
  defaultFont: {
    family: "Times",
    weight: "normal",
    style: "normal",
  },
};

function ViewPDFPage() {
  const [currentPage, setCurrentPage] = useState(1);
  const [numPages, setNumPages] = useState();
  const [documentLoading, setDocumentLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const file_url = searchParams.get("file_url");
  function onDocumentLoadSuccess(val) {
    const { numPages } = val;
    setNumPages(numPages);
    setDocumentLoading(false);
  }

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    document.title = "Document";
    window.addEventListener("resize", (e) => {
      setWindowWidth(window.innerWidth);
    });

    return () => {};
  }, []);

  return (
    <div
      className=""
      style={{ width: windowWidth < 1000 ? windowWidth : 1000 }}
    >
      <div className="Example">
        <div className="Example__container">
          <div
            className="Example__container__document"
            style={{ overflowY: "auto" }}
          >
            <Document
              file={file_url}
              onLoadSuccess={onDocumentLoadSuccess}
              options={options}
              onLoadError={(e) => {
                console.log(e);
                setIsError(true);
              }}
              loading={
                <div className="vh-100" style={{}}>
                  Loading...
                </div>
              }
            >
              <Page
                key={`page_${currentPage}`}
                pageNumber={currentPage}
                width={windowWidth < 1000 ? windowWidth : 1000}
              />
            </Document>
          </div>
        </div>
      </div>
      {!documentLoading && (
        <div
          className=" d-flex justify-content-center position-sticky bottom-0"
          style={{ width: windowWidth, zIndex: 200 }}
        >
          <div className=" d-flex gap-2 align-items-baseline btn-group bg-light p-2">
            <button
              className=" btn btn-light shadow click-animation btn-sm"
              onClick={() => setCurrentPage((prev) => prev - 1)}
              disabled={currentPage === 1}
            >
              <i class="bi bi-caret-left-square text-primary"></i>
            </button>
            <span>
              Page {currentPage} /{" "}
              <span className=" fw-semibold"> {numPages}</span>
            </span>
            <button
              disabled={numPages === currentPage}
              className=" btn btn-light shadow  click-animation btn-sm"
              onClick={() => setCurrentPage((prev) => prev + 1)}
            >
              <i class="bi bi-caret-right-square text-primary"></i>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ViewPDFPage;
