import "../../css/SuccessErrorAnimation.css";

function SuccessErrorAnimation(props) {
  const { type } = props;
  return (
    <>
      {type === "success" ? (
        <div class="wrapper">
          <svg
            class="checkmark success"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
          >
            <circle
              class="checkmark_circle_success"
              cx="26"
              cy="26"
              r="25"
              fill="none"
            />
            <path
              class="checkmark_check"
              fill="none"
              d="M14.1 27.2l7.1 7.2 16.7-16.8"
              stroke-linecap="round"
            />
          </svg>
        </div>
      ) : null}

      {type === "error" ? (
        <div class="wrapper">
          <svg
            class="checkmark error"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
          >
            <circle
              class="checkmark_circle_error"
              cx="26"
              cy="26"
              r="25"
              fill="none"
            />
            <path
              class="checkmark_check"
              stroke-linecap="round"
              fill="none"
              d="M16 16 36 36 M36 16 16 36
        "
            />
          </svg>
        </div>
      ) : null}
    </>
  );
}

export default SuccessErrorAnimation;
