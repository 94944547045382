import { toast } from "react-toastify";

export const successToast = (msg) => {
  toast.success(msg, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 1000,
    theme: "colored",
  });
};

export const errorToast = (msg) => {
  toast.error(msg, {
    position: toast.POSITION.TOP_RIGHT,
    theme: "colored",
  });
};
export const warningToast = (msg) => {
  toast.warning(msg, {
    position: toast.POSITION.TOP_RIGHT,
    theme: "colored",
  });
};

export const bottomToast = (msg) => {
  toast.info(msg, {
    position: toast.POSITION.BOTTOM_CENTER,
    theme: "colored",
  });
};
