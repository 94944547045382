import coming_soon from "./../img/coming_soon.svg";

function ComingSoonPage() {
  return (
    <>
      <div className=" d-flex flex-column justify-content-between align-items-center mt-4 gap-4">
        <img
          className=""
          src={coming_soon}
          style={{
            objectFit: "contain",
            height: "100%",
            width: "40vw",
          }}
        />
        <p className="h1 text-center">Coming Soon...</p>
      </div>
    </>
  );
}

export default ComingSoonPage;
