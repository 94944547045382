import axios from "axios";
import { errorToast } from "../../react-toastfiy/toast";

const MT_URL = "https://api.meratractor.com";
// const MT_URL = "http://192.168.0.130:8001";
// const MT_URL = "http://localhost:8000";
// const MT_URL = "http://192.168.72.179:8001";
// const MT_URL = "http://192.168.1.14:8001";

const cookieAxios = axios.create({
  baseURL: MT_URL,
  withCredentials: true,
});

class AuctionService {
  MeratractorLogin = async (formData) => {
    return axios({
      method: "post",
      url: `${MT_URL}/login-react`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetAllApprovalPending = async (formData) => {
    return axios({
      method: "post",
      url: `${MT_URL}/auctions/get-auction-erp-auction-approval-pending`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAllAssetApprovalPending = async (formData) => {
    return axios({
      method: "post",
      url: `${MT_URL}/auctions/get-auction-erp-asset-approval-pending`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  ClosedApprovalRequest = async (formData) => {
    return axios({
      method: "post",
      url: `${MT_URL}/auctions/update-auction-erp-auction-close-request`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  UpdateApprovalStatus = async (formData) => {
    return axios({
      method: "post",
      url: `${MT_URL}/auctions/update-auction-erp-asset-status`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAllPaymentPending = async (formData) => {
    return axios({
      method: "post",
      url: `${MT_URL}/auctions/get-auction-erp-auctions-payment-pending`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAllAssetPaymentPending = async (formData) => {
    return axios({
      method: "post",
      url: `${MT_URL}/auctions/get-auction-erp-assets-payment-pending`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAllCompletedAuction = async (formData) => {
    return axios({
      method: "post",
      url: `${MT_URL}/auctions/get-completed-auctions-from-date-range`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  EditChangeClosedRequest = async (formData) => {
    return axios({
      method: "post",
      url: `${MT_URL}/auctions/edit-change-close-request`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAuctionChangeClosedRequestApprovalPending = async (formData) => {
    return axios({
      method: "post",
      url: `${MT_URL}/auctions/get-completed-auctions-change-close-request-st`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  ClosedRequestApproval = async (formData) => {
    return axios({
      method: "post",
      url: `${MT_URL}/auctions/update-change-close-request`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetFinancierWiseAuctionList = async (formData) => {
    return axios({
      method: "post",
      url: `${MT_URL}/auctions/auction-erp-st-dashboard`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetStateWiseFinancierAuctionList = async (formData) => {
    return axios({
      method: "post",
      url: `${MT_URL}/auctions/auction-erp-admin-dashboard`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  ApprovedPendingCount = async (formData) => {
    return axios({
      method: "post",
      url: `${MT_URL}/auctions/get-auction-erp-auction-approval-pending-count`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  PaymentPendingCount = async (formData) => {
    return axios({
      method: "post",
      url: `${MT_URL}/auctions/get-auction-erp-auctions-payment-pending-count`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  AuctionChangeClosedRequestApprovalPendingCount = async (formData) => {
    return axios({
      method: "post",
      url: `${MT_URL}/auctions/get-completed-auctions-change-close-request-st-count`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetBackoutApprovalCount = async (formData) => {
    return axios({
      method: "get",
      url: `${MT_URL}/auctions/get-backout-charges-no-data-count`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetState = async (formData) => {
    return axios({
      method: "post",
      url: `${MT_URL}/auctions/get-user-state-permissions`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetSegmentType = async (formData) => {
    return axios({
      method: "get",
      url: `${MT_URL}/auctions/get-all-segments`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAllAuctionReport = async (formData) => {
    return axios({
      method: "post",
      url: `${MT_URL}/auctions/get-auction-representative-reports-auctions-listing`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAuctionRepresentativeReportsAssetsListing = async (formData) => {
    return axios({
      method: "post",
      url: `${MT_URL}/auctions/get-auction-representative-reports-assets-listing`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAllAuctionState = async (formData) => {
    return axios({
      method: "post",
      url: `${MT_URL}/auctions/get-all-states-open-api`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetUserList = async (formData) => {
    return axios({
      method: "post",
      url: `${MT_URL}/wallet/get_phone_name`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  UpdateAuctionSummaryAsset = async (formData) => {
    return axios({
      method: "post",
      url: `${MT_URL}/auctions/update-auction-summary-asset`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAllAssetForBackoutApprovalPending = async (formData) => {
    return axios({
      method: "post",
      url: `${MT_URL}/auctions/get-backout-charges-no-data`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  UpdateBackoutChargesStatus = async (formData) => {
    return axios({
      method: "post",
      url: `${MT_URL}/auctions/update-backout-charges-status`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAuctionsListBondKYCDownload = async (formData) => {
    return axios({
      method: "post",
      url: `${MT_URL}/auctions/auction-list-bond-kyc-download`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAssetListBondKYCDownload = async (formData) => {
    return axios({
      method: "post",
      url: `${MT_URL}/auctions/asset-list-bond-kyc-download`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAuctionWiseReleaseOrBankNOCPending = async (formData) => {
    return axios({
      method: "post",
      url: `${MT_URL}/auctions/auction-wise-release-or-banknoc-pending`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAssetWiseReleaseOrBankNOCPending = async (formData) => {
    return axios({
      method: "post",
      url: `${MT_URL}/auctions/asset-wise-release-or-banknoc-pending`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  ReleaseDoneOfAsset = async (formData) => {
    return axios({
      method: "post",
      url: `${MT_URL}/auctions/release-done-of-asset`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  BankNOCDoneOfAsset = async (formData) => {
    return axios({
      method: "post",
      url: `${MT_URL}/auctions/banknoc-done-of-asset`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  AuctionWonReports = async (formData) => {
    return axios({
      method: "post",
      url: `${MT_URL}/auctions/auction-won-report`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  ClientCompletedAuctionIDsForERP = async (formData) => {
    return axios({
      method: "post",
      url: `${MT_URL}/client/get-completed-auction-ids-for-erp`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  ClientCompletedAuctionDataForERP = async (formData) => {
    return axios({
      method: "post",
      url: `${MT_URL}/client/get-completed-auction-data-for-erp`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAuctionERPAuctionsPaymentPendingCount = async (formData) => {
    return axios({
      method: "post",
      url: `${MT_URL}/auctions/get-auction-erp-auctions-payment-pending-count`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetBrandList = async (formData) => {
    return axios({
      method: "post",
      url: `${MT_URL}/auctions/get-brand-list`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetUserStateList = async (formData) => {
    return axios({
      method: "POST",
      url: `${MT_URL}/user/api/statelist`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetAuctionInDateRangeList = async (formData) => {
    return axios({
      method: "POST",
      url: `${MT_URL}/dashboard/get-auction-in-date-range`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetAuctionUserList = async (formData) => {
    return axios({
      method: "POST",
      url: `${MT_URL}/dashboard/auction-user-data`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAssetWiseBiddingData = async (formData) => {
    return axios({
      method: "post",
      url: `${MT_URL}/dashboard/asset-wise-bidding-data`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetMMFSLLotsCount = async (formData) => {
    return axios({
      method: "post",
      url: `${MT_URL}/dashboard/get-mmfsl-lots-count`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetMMFSLLotsCountModalData = async (formData) => {
    return axios({
      method: "post",
      url: `${MT_URL}/dashboard/get-lot-wise-assets`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetMMFSLLotsCountModal = async (formData) => {
    return axios({
      method: "post",
      url: `${MT_URL}/dashboard/get-lot-wise-unique-states`,
      data: formData,
    })
      .then((response) => {
        console.log({ response });
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  RetryACRGeneration = async (formData) => {
    return axios({
      method: "post",
      url: `${MT_URL}/auctions/retry-acr-generation`,
      data: formData,
    })
      .then((response) => {
        console.log({ response });
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAuctionCompletionDetails = async (formData, source = {}) => {
    return axios({
      method: "post",
      url: `/operations/get-auction-completion-state-financier-wise`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        console.log({ response });
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetUserAnalysisData = async (formData, source = {}) => {
    return axios({
      method: "get",
      url: `${MT_URL}/dashboard/get-this-month-active-dormant-churn-users`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        console.log({ response });
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
}

export default new AuctionService();
