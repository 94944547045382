import { useEffect, useState } from "react";
import Confetti from "react-confetti";
import SuccessErrorAnimation from "./Animations/SuccessErrorAnimation";

function ThankYouPage() {
  useEffect(() => {
    document.title = "Thank You!";

    setTimeout(() => {
      setrun(false);
    }, [1500]);
  }, []);

  const [run, setrun] = useState(true);
  return (
    <>
      <div className=" container">
        <div
          className=" d-flex flex-column align-items-center justify-content-center"
          style={{ height: "70vh" }}
        >
          <Confetti
            width={window.innerWidth}
            height={window.innerHeight}
            gravity={0.5}
            recycle={run}
            numberOfPieces={100}
          />
          <span className="text-success fw-bold fs-3 my-2">Thank You! </span>
          <SuccessErrorAnimation type={"success"} />

          <p>
            Your document has been signed successfully. Now you can close the
            window.
          </p>
        </div>
      </div>
    </>
  );
}

export default ThankYouPage;
