import { useEffect } from "react";

import SuccessErrorAnimation from "./Animations/SuccessErrorAnimation";
import signzService from "../services/signz.service";
import { useLocation } from "react-router-dom";

function FailurePage() {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const data_id = searchParams.get("data_id");

  useEffect(() => {
    document.title = "Thank You!";

    const form_data = new FormData();
    form_data.append("data_id", data_id);

    signzService.SignZFailureCallback(form_data);
  }, []);
  return (
    <>
      <div className=" container">
        <div
          className=" d-flex flex-column align-items-center justify-content-center"
          style={{ height: "70vh" }}
        >
          <span className="text-danger fw-bold fs-3 my-2">
            Something Went Wrong!{" "}
          </span>

          <SuccessErrorAnimation type={"error"} />

          <p>
            There has been an error while signing a document please{" "}
            <b> try again</b>, or contact to the concerned person.
          </p>
        </div>
      </div>
    </>
  );
}

export default FailurePage;
