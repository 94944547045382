import React, { useEffect } from "react";
import Authservice from "../services/auth.service";
import { useHistory } from "react-router-dom";
import { errorToast } from "../react-toastfiy/toast";

function Logout() {
  let history = useHistory();

  useEffect(() => {
    async function logout() {
      const result = await Authservice.logout();
      result.status === 200 && window.location.replace("/sign-in");
      result.status !==200 && errorToast(result.msg);
      
    }
    logout();
    // window.location.replace('/')
  });

  return null;
}

export default Logout;
